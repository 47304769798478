export const metaLabel = {
	bookId: "教科書ID",
	subject: "教科名",
	grade: "学年",
	semester: "学期区分",
	totalPages: "ページ数",
	tooltip: "ツールチップ",
	direction: "綴じ方",
	width: "横（px）",
	"view.width": "横（px）",
	height: "縦（px）",
	"view.height": "縦（px）",
	author: "作成者",
	coverPath: "カバー経路",
	lastModified: "最終修正日",
	nowEditing: "修正中",
	saved: "保存回数",
	indexEnd: "目次ページの設定",
	bookType: "タイプ",
};

export const subject = {
	kokugo: "国語",
	rika: "理科",
	sansu: "算数",
	seikatsu: "生活",
	shakai: "社会",
	shosha: "書写",
	doutoku: "道徳",
	hoken: "保健",
	katei: "家庭",
	gijutu: "技術",
	eigo: "英語",
};

export const grade = {
	"1": "1年",
	"2": "2年",
	"3": "3年",
	"4": "4年",
	"5": "5年",
	"6": "6年",
};

export const semester = {
	"1": "上",
	"2": "下",
};

export const bookType = {
	"t": "デジタル教科書",
	"b": "デジタルブック",
};

export const tooltip = {
	nouse: "使用しない",
	use: "使用する",
};

export const direction = {
	rtl: "右 → 左",
	ltr: "左 → 右",
};

export const indexEnd = {
	"2": "2ページまで",
	"4": "4ページまで",
};

export const loadingMsg = {
	READING: "教科書データーを読み込み中...",
	UPLOADING: "サーバーにアップロード中...",
	LOADING: "ローディング中...",
	NEW: "新しい教科書を作成中...",
	SAVING: "保存中...",
	DELETING: "削除中...",
	CLEAN: "整理中...",
    CHANGE: "変更中...",
	CSV_IMPORT: "適用中..."
};

export const dataModeConst = {
	EDITOR: "edit",
	DRAW: "draw",
	GROUP: "group",
	LINK_PAGES: "linkPages",
	CHECK_SHEET: "checkSheet",
	EDIT_SVG: "svg",
	EDIT_SUBVIEW_SVG: "subviewSvg",
	POPUP: "popup",
};

export const objectModeConst = {
	subView: "subView",
	myEditor: "subView",
	icon: "subView",
	meaning: "meaning",
	form: "form",
	pageLink: "pageLink",
	videoLink: "videoLink",
	audioLink: "audioLink",
	redLine: "redLine",
	mask: "mask",
	block: "block",
	postIt: "postIt",
	group: "group",
	check: "subView",
	popupLink: "popupLink",
	popupTarget: "popupLink",
	restudy: "restudy",
};

export const contents = {
	login: "login",
	home: "home",
	editor: "editor",
	setting: "setting",
	account: "account",
	import: "import",
};

export const modalMsg = {
	UPLOAD: "アップロードしますか？",
	COMPRESS: "圧縮しますか？",
	DELETE: "本当に削除しますか？",
	INIT: "本当に初期化しますか？",
	DISCARD: "変更事項を破棄しますか？<br>(アップロードしたデーターは保存されます。)",
	UPLOADED: "アップロードを完了しました。",
	EDIT_COMPLETE: "編集中の教科書を完了に変更しますか？",
	OVERWRITE: "本当に差し替えしますか？<br>(元の内容が削除されます。)",
	IMAGE_FIND_FAIL: "レポジトリに存在しない画像です。",
	IMAGE_EXIST: "もうレポジトリに存在しているファイル名です。",
	FILE_EXIST: "{FILENAME}はもうレポジトリに存在しているファイル名です。",
	OVER_CAPACITY: "一度にアップロードできるファイル容量（{MAX}mb）を超えました。",
	PASSWORD_EMPTY: "パスワードを入力してください。",
	PASSWORD_NOT_MATCHED: "パスワードが一致しません。",
	PASSWORD_CHANGED: "パスワードを変更しました。<br>またログインしてください。",
	MAX_BOOK: "制作可能な最大冊数を超えました。",
	FULL_EDITING: "全ページが編集中（またはロック）です。",
	USER_INFO_CHANGE: "本当に変更しますか？",
	CLEAN: "不要なファイルを整理しますか？",
	ERROR: "エラーが発生しました。",
	LOCK_ERROR: "入力したページ範囲が間違いました。",
	LOCK_FAIL_ERROR: "ほかの編集者がロックしたページがあります。",
	SAVE: "変更した内容を保存しますか？",
	DELETE_PAGE: "最後ページが削除されます。<br>本当に削除しますか？",
	PAGE_MOVE_ERROR: "他の編集者が編集（またはロック）しているページです。",
};

export const modalTitle = {
	ALERT: "アラート",
	WARNING: "警告",
	ERROR: "エラー",
	SUCCESS: "完了",
};

export const componentTitle = {
	EDITING_BOOKS: "編集中の教科書",
	BOOK_LISTS: "MY教科書リスト",
	GROUP_BOOK_LISTS: "グループ教科書リスト",
	CSV_IMPORT: "教科書のCSVインポート",
	ACCOUNT_SETTING: "アカウント設定",
	PAGE_RECEIPT: "発行履歴",
	BOOK_SETTING: "教科書設定",
	ADMIN_SETTING: "管理者設定",
	LOG: "ログ",
};

export const otherMsg = {
	NO_BOOK_MSG: "編集中の教科書がありません。",
};

export const propertyInputName = {
	id: "id",
	page: "page",
	linkLevel: "linkLevel",
	zIndex: "zIndex",
	iFrame: "iFrame",
	width: "width",
	height: "height",
	top: "top",
	left: "left",
	opacity: "opacity",
	groupName: "group",
	groupIndex: "groupIndex",
	class: "addClass",
	editor: "editorUse",
	icon: "type",
	iconType: "type",
	contents: "contents",
	audioIndex: "index",
	moveAt: "moveAt",
	playAt: "playAt",
	file: "fileKey",
	audioValue: "value",
	audioTime: "time",
	audioStart: "start",
	tooltip: "tooltip",
	subtitle: "subtitleKey",
	subtitleEng: "subtitleEngKey",
	color: "color",
	line: "line",
	ignore: "ignore",
	myEditorCss: "myEditorCss",
	htmlContent: "zipKey",
	htmlFileName: "htmlFileName",
	linkPages: "linkPages",
	fontSize: "fontSize",
	lineHeight: "lineHeight",
	marginLeft: "marginLeft",
	marginTop: "marginTop",
	fontColor: "color",
	bold: "fontWeight",
	absolute: "position",
	htmlCheck: "contents",
	pdf: "pdfKey",
	pdfCheck: "pdf",
	popupLinkCheck: "popUpLink",
	exLink: "path",
	exLinkCheck: "link",
	form: "form",
	checkSheet: "checkSheet",
	svg: "svg",
	points: "points",
	d: "d",
	startTime: "startTime",
	endTime: "endTime",
	useIcon: "class",
	popupPosition: "position",
	popupTitle: "title",
	popupKey: "popupKey",
	popupType: "type",
	popupTarget: "css",
	addEditorText: "text",
	textList: "textList",
	maskColorSelect: "fill",
	maskColorText: "fill",
	blockColorSelect: "backgroundColor",
	blockColorText: "backgroundColor",
	identify: "identify",
	thumbKey: "thumbKey",
	editorImage: "myEditorImage",
	editorImage_bg: "myEditorImage_bg",
	answerRate: "desc",
	popupComment: "comment",
	restudyPage: "page",
	restudySemester: "name",
	restudyBookName: "setName",
	kakezuCheck: "kakezuCheck",
	kakezu: "kakezu",
	title: "title",
	fullScreen: "fullScreen",
	svgType: "svgType",
};

export const propertyInputLabel = {
	id: "ID",
	page: "ページ",
	linkLevel: "表示レベル",
	zIndex: "レイヤー優先度",
	iFrame: "HTMLコンテンツ",
	width: "横",
	height: "縦",
	top: "Y座標",
	left: "X座標",
	opacity: "透明度",
	groupName: "グループ",
	groupIndex: "グループ番号",
	class: "addClass",
	editor: "エディター",
	icon: "アイコン",
	iconType: "タイプ",
	contents: "コンテンツ",
	audioIndex: "順番",
	moveAt: "ページ転換時間",
	playAt: "転換後再生時間",
	linkPageData: "リンク先ページ",
	file: "ファイル",
	audioValue: "value",
	audioTime: "time",
	audioStart: "start",
	tooltip: "ツールチップ",
	subtitle: "字幕",
	subtitleEng: "字幕（英）",
	color: "色",
	line: "line",
	ignore: "自動登録除外",
	editorImage: "文字・背景画像",
	editorImage_bg: "背景画像",
	myEditorCss: "エディタ位置調整",
	videoUpload: "動画アップ",
	htmlContent: "HTMLコンテンツ",
	htmlFileName: "HTMLファイル名",
	linkPages: "リンクページ",
	fontSize: "フォントサイズ",
	lineHeight: "行間隔",
	marginLeft: "横書き位置",
	marginTop: "縦書き位置",
	fontColor: "フォントの色",
	bold: "太字",
	absolute: "文字ボックス変更",
	htmlCheck: "HTMLコンテンツ",
	pdf: "PDF",
	pdfCheck: "PDF",
	exLink: "外部リンク",
	exLinkCheck: "外部リンク",
	popupLinkCheck: "ポップアップリンク",
	form: "形式番号",
	checkBackground: "チェックボックス",
	svg: "SVG",
	points: "SVG Points",
	d: "SVG d",
	startTime: "再生時間位置",
	endTime: "終了時間位置",
	useIcon: "アイコン使用",
	popupPosition: "ポップアップ位置",
	popupTitle: "タイトル",
	popupKey: "ポップアップ画像",
	popupType: "タイプ",
	popupTarget: "表示位置指定",
	addEditorText: "文字ボックス追加",
	textList: "テキスト順番",
	maskColorSelect: "色",
	maskColorText: "色コード",
	blockColorSelect: "色",
	blockColorText: "色コード",
	identify: "参照",
	thumbKey: "サムネールID",
	answerRate: "正答率",
	popupComment: "コメント",
	restudyPage: "移動ページ",
	restudySemester: "学期",
	restudyBookName: "教科書名",
	kakezuCheck: "掛図モード",
	kakezu: "掛図モード順番",
	title: "タイトル",
	fullScreen: "全画面表示",
	svgType: "SVGタイプ",
};

export const propertyToolTip = {
	id: "各クリックポイントオブジェクトのID",
	page: "紙面ページ",
	linkLevel: "クリックポイントの表示レベル<br/>（自動的に設定されますが、特別な状況には編集します）",
	zIndex: "重なりの順序を数値で指定",
	iFrame: "htmlコンテンツファイル（.zipファイル）",
	width: "",
	height: "",
	top: "クリックポイントのY座標",
	left: "クリックポイントのX座標",
	opacity: "",
	groupName: "",
	groupIndex: "",
	class: "",
	editor: "My教科書エディターのオブジェクト(レイヤー)を選択<br/>・含め　・除外　・専用",
	iconType: "",
	contents: "",
	audioData: "",
	moveAt: "",
	playAt: "",
	file: "",
	audioValue: "",
	audioTime: "",
	audioStart: "",
	tooltip: "",
	subtitle: "",
	color: "",
	line: "",
	ignore: "",
	htmlContent: "",
	linkPages: "拡大画面のポップアップ（サムネールリンク）",
	fontSize: "",
	lineHeight: "",
	marginLeft: "",
	marginTop: "",
	popupLinkCheck: "",
	exLink: "",
	form: "1～99まで入力できます。",
	checkSheet: "使用するチェックボックスの種類を選択。",
	svg: "多角形オブジェクトを設定します。",
	popupDesc: "特別な字を使って編集できます。<br/>" +
		"{b}あいうえお{/b} → 「あいうえお」が太字になる<br/>" +
		"{r} → 赤い「▼」がでる<br/>" +
		"{h}漢字(かんじ){/h} → 「漢字」の上に「かんじ」というフリガナが出る<br/>"
	,
	popupTarget: "クリックポイントとポップアップ表示位置が違う時、表示位置設定",
	absolute: "文字ボックスの位置調整方式を変更します。",
	thumbKey: "リンクページのサムネールをレポジトリからサムネールIDをコーピして登録できます。",
	kakezuCheck: "掛図モードを使う場合チェックしてください。",
	kakezu: "掛図モードの順番を設定します。（数字）で入力",
};

export const csvToolTip = {
	book: "教科書別の基本情報を設定できます。",
	chapter: "",
	subView: "教科書に掲載の問題や図版はワンクリックで、大きく表示できます。",
	editor: "教科書上のテキスト，図形，写真，挿絵などを自由にレイアウトできます。",
	audio: "",
	html: "",
	video: "",
};

export const settingsToolTip = {
	chapter: "ページ内の“章”のような設定ができます。<br />（見たい紙面へ移動できる「しおり」機能）",
};

export const topPanelPageViewType = {
	all: "両",
	left: "左",
	right: "右",
};

export const topPanelMyEditorType = {
	0: "文字・背景画像",
	1: "背景画像",
};

export const topPanelMyEditorTextType = {
	0: "編集OFF",
	1: "編集ON",
};

export const topPanelObjIdToggle = {
	0: "ID OFF",
	1: "ID ON"
};

export const topPanelBgToggle = {
	0: "紙面 OFF",
	1: "紙面 ON"
};

export const subjectKeyName = {
	sansu: "sansu",
	rika: "rika",
	kokugo: "kokugo",
	shakai: "shakai",
	seikatsu: "seikatsu",
	shosha: "shosha",
	doutoku: "doutoku",
	hoken: "hoken",
	katei: "katei",
	gijutu: "gijutu",
	eigo: "eigo",
};

export const iconTypeOptions = [
	{value: 0, level: 2, name: "なし", className: "no", width: 100, height: 100},

	{value: 23, level: 3, name: "動画", className: "movie_r6", width: 37, height: 30},
	{value: 24, level: 3, name: "動画(学習者用)", className: "movie_qr", width: 37, height: 30},
	{value: 25, level: 3, name: "音声", className: "sound", width: 37, height: 30},
	{value: 26, level: 3, name: "音声(学習者用)", className: "sound_qr", width: 37, height: 30},
	{value: 27, level: 3, name: "資料", className: "siryou", width: 37, height: 30},
	{value: 28, level: 3, name: "資料(学習者用)", className: "siryou_qr", width: 37, height: 30},
	{value: 29, level: 3, name: "コンテンツ", className: "cont", width: 37, height: 30},
	{value: 30, level: 3, name: "コンテンツ(学習者用)", className: "cont_qr", width: 37, height: 30},
	{value: 31, level: 3, name: "ワークシート", className: "sheet", width: 37, height: 30},
	{value: 32, level: 3, name: "ワークシート(学習者用)", className: "sheet_qr", width: 37, height: 30},
	{value: 33, level: 3, name: "シミュレーション", className: "sim", width: 37, height: 30},
	{value: 34, level: 3, name: "振り返り", className: "furika", width: 37, height: 30},
	{value: 35, level: 3, name: "スライドショー", className: "slide", width: 37, height: 30},
	{value: 36, level: 3, name: "スライドショー(学習者用)", className: "slide_qr", width: 37, height: 30},
	{value: 37, level: 3, name: "外部リンク", className: "link", width: 37, height: 30},
	{value: 38, level: 3, name: "外部リンク(学習者用)", className: "link_qr", width: 37, height: 30},
	{value: 39, level: 3, name: "テキスト活用", className: "text", width: 37, height: 30},
	{value: 40, level: 3, name: "練習", className: "rensyu", width: 37, height: 30},
	{value: 41, level: 3, name: "練習(学習者用)", className: "rensyu_qr", width: 37, height: 30},
	{value: 42, level: 3, name: "My Picture Dictonary", className: "pd", width: 37, height: 30},
	{value: 43, level: 3, name: "My Picture Dictonary(学習者用)", className: "pd_student", width: 37, height: 30},
	{value: 44, level: 3, name: "デジタル地図", className: "map", width: 37, height: 30},
	{value: 45, level: 3, name: "デジタル地図(色違い)", className: "map_red", width: 37, height: 30},
	{value: 46, level: 3, name: "登場人物", className: "toujo", width: 37, height: 30},
	{value: 47, level: 1, name: "解答", className: "answer", width: 31, height: 15},

	{value: 51, level: 3, name: "あらすじ", className: "arasuji", width: 37, height: 30},
	{value: 52, level: 3, name: "問題集実力アップ", className: "mondai_jitsu", width: 37, height: 30},
	{value: 53, level: 3, name: "問題集基礎から", className: "mondai_kiso", width: 37, height: 30},
	{value: 54, level: 3, name: "アニメ", className: "movie_anime", width: 37, height: 30},
	{value: 55, level: 3, name: "指導者用デジタル教科書", className: "shidousha_digital", width: 37, height: 30},
	{value: 56, level: 3, name: "シミュレーション", className: "sim_student", width: 37, height: 30},

	{value: 1, level: 1, name: "解答", className: "answer", width: 31, height: 15},
	{value: 2, level: 3, name: "コンテンツ", className: "content", width: 37, height: 30},
	{value: 3, level: 3, name: "（共通）コンテンツ", className: "content_common", width: 37, height: 30},
	{value: 4, level: 3, name: "外部リンク", className: "external_link", width: 37, height: 30},
	{value: 5, level: 3, name: "（共通）外部リンク", className: "external_link_common", width: 37, height: 30},
	{value: 6, level: 3, name: "フラッシュカード", className: "flashcards", width: 37, height: 30},
	{value: 22, level: 3, name: "フラッシュカード（共通）", className: "flashcards_common", width: 37, height: 30},
	{value: 7, level: 3, name: "ガイド", className: "guide", width: 31, height: 30},
	{value: 8, level: 1, name: "ページリンク", className: "page_link", width: 31, height: 23},
	{value: 9, level: 3, name: "音声", className: "reading_sound", width: 31, height: 30},
	{value: 10, level: 3, name: "（共通）音声", className: "reading_sound_common", width: 31, height: 30},
	{value: 11, level: 3, name: "関連資料", className: "related_document", width: 37, height: 30},
	{value: 12, level: 3, name: "（共通）関連資料", className: "related_document_common", width: 37, height: 30},
	{value: 13, level: 3, name: "シミュレーション", className: "simulation", width: 37, height: 30},
	{value: 14, level: 3, name: "（共通）シミュレーション", className: "simulation_common", width: 37, height: 30},
	{value: 15, level: 3, name: "スライドショー", className: "slideshow", width: 37, height: 30},
	{value: 16, level: 1, name: "（共通）音声", className: "voice_common", width: 37, height: 23},
	{value: 17, level: 3, name: "ワークシート", className: "work_sheet", width: 37, height: 30},
	{value: 18, level: 3, name: "（共通）ワークシート", className: "work_sheet_common", width: 37, height: 30},
	{value: 19, level: 3, name: "動画", className: "movie", width: 37, height: 30},
	{value: 20, level: 3, name: "（共通）動画", className: "movie_common", width: 37, height: 30},
	{value: 21, level: 1, name: "地図", className: "map", width: 31, height: 15},
];

export const defaultIcon = iconTypeOptions[1];

export const videoIconOptions = [
	{value: 1, level: 3, name: "動画", className: "movie", width: 37, height: 30},
	{value: 2, level: 3, name: "（共通）動画", className: "movie_common", width: 37, height: 30},
	{value: 3, level: 3, name: "関連資料", className: "related_document", width: 37, height: 30},
	{value: 4, level: 3, name: "（共通）関連資料", className: "related_document_common", width: 37, height: 30},
];

export const defaultVideoIcon = videoIconOptions[0];

export const pageLinkIconOptions = [
	{value: 0, level: 2, name: "なし", className: "no", width: 100, height: 100},
	{value: 1, level: 3, name: "左", className: "move_left", width: 30, height: 30},
	{value: 2, level: 3, name: "右", className: "move_right", width: 30, height: 30},
];

export const metaDataConst = {
	"author": "author",
	"bookId": "bookId",
	"coverPath": "coverPath",
	"grade": "grade",
	"lastModified": "lastModified",
	"nowEditing": "nowEditing",
	"saved": "saved",
	"semester": "semester",
	"subject": "subject",
	"totalPages": "totalPages",
	"view.height": "view.height",
	"view.width": "view.width",
	"direction": "direction",
	"height": "height",
	"width": "width",
	"view": "view",
};

export const directionName = {
	rtl: "rtl",
	ltr: "ltr",
};

export const dragType = {
	GROUP: 'group',
	GROUP_ITEM: 'group_item',
	LINK_PAGES: 'link_pages',
	LINK_PAGES_ITEM: "link_pages_item",
	GUIDE_MODE: "guideMode",
	GUIDE_MODE_ITEM: "guideMode_item",
};

export const subViewType = {
	common: 0,
	shakaiDesc: 1,
	shakaiRedLine: 2,
	rikaBlock: 3,
	rikaScroll: 4,
	sansuAnswer: 5,
	kokugoPageLink: 6,
	rikaMask: 7,
	rikaCheck: 8,
	subMenu: 9,
	rikaAnswer: 10,
	subViewInSubView: 11,
	rikaScrollNew: 12,
	shakaiMask: 13,
	sansuScroll: 14,
	sansuRestudy: 15,
	subPopup: 16,
	subMovePage: 17
};

export const subViewRect = {
	rikaMask: 'mask',
	rikaCheck: 'check',
	rikaBlock: "block",
	rikaAnswer: "answer",
	sansuAnswer: "answer",
	shakaiRedLine: "redLine",
	subViewInSubView: "subViewLink",
	shakaiMask: "block",
	sansuScroll: "answer",
	sansuRestudy: "restudy",
	subPopup: "subPopup",
	subMovePage: "subMovePage"
};

export const rikaScroll = {
	scrollTop: -560,
};

export const sansuAnswerType = {
	answer: {
		button: "answerButton",
		contents: ["answerMask", "answerText"],
	},
	toggle: {
		contents: ["toggleMask", "toggleText"],
	},
	expression: {
		button: "expressionButton",
		contents: "expressionContents",
		selectBoxText: "式",
	},
	graph: {
		button: "graphButton",
		contents: "graphContents",
		selectBoxText: "図",
	},
	equal: {
		button: "equalButton",
		contents: "equalContents",
		selectBoxText: "=",
	},
	queEqual: {
		button: "queEqualButton",
		contents: "queEqualContents",
		selectBoxText: "ロ=",
	},
	resolve: {
		button: "resolveButton",
		contents: "resolveContents",
		selectBoxText: "答え",
	},
	mask: {
		button: "maskButton",
		contents: "maskContents",
		selectBoxText: "空",
	}
};

export const rikaAnswerType = {
	answer: {
		button: {
			name: "answerButton",
			width: 125,
			height: 81,
		},
		contents: {
			name: "answerMask",
			width: 125,
			height: 81,
		},
		exampleButton: {
			name: "exampleButton",
			width: 125,
			height: 81,
		},
	},
};

export const subMenuTitleOptions = [
	{value: "sub-menu-title-contents", name: "コンテンツ"},
	{value: "sub-menu-title-video", name: "動画"},
	{value: "sub-menu-title-both", name: "動画・コンテンツ"},
	{value: "sub-menu-title-worksheet", name: "ワークシート"},
	{value: "sub-menu-title-related_document", name: "関連資料"},
	{value: "sub-menu-title-hkotoba", name: "ことば"},
];

export const subMenuIconOptions = [
	{value: "movie", name: "動画"},
	{value: "movie_common", name: "動画（共通）"},
	{value: "content", name: "コンテンツ"},
	{value: "content_common", name: "コンテンツ（共通）"},
	{value: "worksheet", name: "ワークシート"},
	{value: "worksheet_common", name: "ワークシート（共通）"},
	{value: "related_document", name: "「動画用」関連資料"},
	{value: "related_document_common", name: "「動画用」関連資料（共通）"},
	{value: "related_document_subview", name: "「画像用」関連資料"},
	{value: "related_document_common_subview", name: "「画像用」関連資料（共通）"},
	{value: "no_icon_subview", name: "「画像用」なし"},
];

export const keyAction = {
	ORDER_CHANGE: "CHANGE",
	ORDER_CANCEL: "CANCEL",
	ORDER_DELETE: "DELETE",
	ORDER_COPY: "COPY",
	ORDER_PASTE: "PASTE",
	ORDER_PASTE_ANSWER_ICON: "PASTE_ANSWER_ICON",
	ORDER_CUT: "CUT",
	ORDER_UNDO: "UNDO",
	ORDER_SAVE: "SAVE",
};

export const keyModel = {
	"27": {order: keyAction.ORDER_CANCEL},
	"37+shift": {order: keyAction.ORDER_CHANGE, target: "width", val: -1},
	"38+shift": {order: keyAction.ORDER_CHANGE, target: "height", val: -1},
	"39+shift": {order: keyAction.ORDER_CHANGE, target: "width", val: 1},
	"40+shift": {order: keyAction.ORDER_CHANGE, target: "height", val: 1},
	"37": {order: keyAction.ORDER_CHANGE, target: "left", val: -1},
	"38": {order: keyAction.ORDER_CHANGE, target: "top", val: -1},
	"39": {order: keyAction.ORDER_CHANGE, target: "left", val: 1},
	"40": {order: keyAction.ORDER_CHANGE, target: "top", val: 1},
	"46": {order: keyAction.ORDER_DELETE},
	"67+ctrl": {order: keyAction.ORDER_COPY},
	"86+ctrl": {order: keyAction.ORDER_PASTE},
	"68+ctrl": {order: keyAction.ORDER_PASTE_ANSWER_ICON},

	"88+ctrl": {order: keyAction.ORDER_CUT},
	"90+ctrl": {order: keyAction.ORDER_UNDO},
	"83+ctrl": {order: keyAction.ORDER_SAVE},
};

export const repoUpload = {
	page: ".png",
	image: ".png",
	video: ".mp4",
	audio: ".mp3",
	thumb: ".png",
	pdf: ".pdf",
	html: ".zip",
	srt: ".srt",
	popup: ".png",
	kakezu: ".png",
};

export const myEditorSuffix = {
	fontSize: "em",
	lineHeight: "%",
	marginTop: "%",
	marginLeft: "%",
	left: "%",
	top: "%",
	width: "%",
};

export const fontColorCode = {
	black: "#000000",
	white: "#FFFFFF",
	orange: "#F5921D",
	red: "#FF0000",
	green: "#00FF00",
	blue: "#0000FF",
	violet: "#1B1871",
};

export const svgPreset = [
	"0,0 50,0 50,50 100,50 100,100 0,100", // ┗
	"50,0 100,0 100,100 0,100 0,50 50,50", // ┛
	"0,0 100,0 100,50 50,50 50,100 0,100", //┏
	"0,0 100,0 100,100 50,100 50,50 0,50", //┓

	"0,0 66,0 66,33 100,33 100,66 66,66 66,100 0,100", //┣
	"33,0 100,0 100,100 33,100 33,66 0,66 0,33 33,33", //┫
	"0,0 100,0 100,33 66,33 66,66 100,66 100,100 0,100", // [
	"0,0 100,0 100,100 0,100 0,66 33,66 33,33 0,33", // ]

	"0,33 33,33 33,0 66,0 66,33 100,33 100,100 0,100", // 凸
	"0,0 100,0 100,66 66,66 66,100 33,100 33,66 0,66", //　ㅜ
	"0,0 33,0 33,33 66,33 66,0 100,0 100,100 0,100", // 凹
	"0,0 100,0 100,100 66,100 66,66 33,66 33,100 0,100", //

	"66,0 100,0 100,66 66,66 66,100 0,100 0,33 33,33 33,0",
	"0,0 66,0 66,33 100,33 100,100 33,100 33,66 0,66",
	"33,0 100,0 66,100 0,100",
	"0,0 66,0 100,100 33,100",
];

export const userLevel = {
	admin: 9,
};

export const editorOptions = [
	{val: 0, name: "含め"},
	{val: 1, name: "除外"},
	{val: 2, name: "専用",},
];

export const popupPositionOptions = [
	{val: "left", name: "左"},
	{val: "right", name: "右"},
];

export const popupTypeOptions = [
	{val: "word", name: "脚注"},
	{val: "author", name: "著者名"},
];

export const answerColorCode = [
	{val: "", name: "入力"},
	{val: "transparent", name: "透明"},
	{val: "#FFFFFF", name: "white"},
	{val: "#FF0000", name: "red"},
	{val: "#00FF00", name: "green"},
	{val: "#0000FF", name: "blue"},
	{val: "#EDB386", name: "orange"},
	{val: "#ACE0EE", name: "skyblue"},
	{val: "#9472A8", name: "purple"},
];

export const maskColorOptions = [
	{value: "", name: "入力"},
	{value: "#FFFFFF"},
	{value: "#000000"},
	{value: "#81AFCB"},
	{value: "#FF0000"},
	{value: "#00FF00"},
	{value: "#0000FF"},
	{value: "#EDB386"},
	{value: "#ACE0EE"},
	{value: "#9472A8"},
];

export const defaultMaskColor = {
	sansu: "#81AFCB",
};

export const defaultSubViewAnswerCss = {
	width: {
		answerButton: 107,
		answerButton_1: 144,
		answerContents: 110,
		toggleContents: 110,
		expressionButton : 88,
		expressionContents : 200,
	},
	height: {
		answerButton: 70,
		answerButton_1: 70,
		answerContents: 47,
		toggleContents: 47,
		expressionButton : 82,
		expressionContents : 100,
	}
};

export const linkLevelOptions = [
	{val: 0, name: "0"},
	{val: 1, name: "1"},
	{val: 2, name: "2"},
	{val: 3, name: "3"},
];

export const subViewSize = {
	default: {
		width: 952,
		height: 674
	},
	large: {
		width: 1080,
		height: 670
	}
};

export const editingMsg = {
	NOT_PERMISSION: "＊共同編集者は「パブリッシン」と「表紙変更」を使用できません。",
	MAKING_BOOK: "＊パブリッシング中に「パブリッシン」と「表紙変更」機能は使用できません。パブリッシングが終わった後、リロードしたらまた使用できます。",
};
